<template>
  <div class="review-plan">
    <button class="change-button" @click="goBack()">
      <svg class="link-img-arrow">
        <use v-bind="{'xlink:href':'#arrow-icon-left'}"></use>
      </svg>
      <p>Back</p>
    </button>
    <h4>STEP 1 OF 3</h4>
    <h2>Review</h2>
    <section class="variations-types-wrap" v-if="productDetails">
      <h2>{{packageData[0].name}}</h2>
      <rz-markdown :text="packageData[0].description"></rz-markdown>
      <h3>Accessible Endpoints</h3>
      <ul v-if="removeEndpoints">
        <li v-for="info in allowed_endpoints" :key="info.key">
          <template v-if="endpoints[info].link !== null">
            <a :href="endpoints[info].link" target="_blank">
              {{endpoints[info].name ? endpoints[info].name : info.split('-').join(' ')}} API
            </a>
          </template>
          <template v-else>
            {{info.split('-').join(' ')}} API
          </template>
        </li>
      </ul>
    </section>
    <div class="btn-wrap">
      <button
        class="btn focus"
        @click="nextStep()"
      >Continue</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { WizardStep } from '@/store/modules/payments';
import { allMetaMixins } from '@/mixin/meta';
import endpoints from '../../../stories/ResourcePage/Endpoints.json';

export default {

  mixins: allMetaMixins(),
  title: 'Review your plan | Roanuz Cricket API',


  data() {
    return {
      packageData: [],
      endpoints,
      allowed_endpoints: [],
    };
  },

  computed: {
    ...mapState({
      packageInfo: (state) => state.paygo.package && state.paygo.package.packageInfo,
      projects: (state) => state.boot.instance.projects,
      currency: (state) => state.user.activeCurrency,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      cartPreview: (state) => state.payments.cartPreview,
    }),
    productDetails() {
      if (this.packageInfo) {
        const packageKey = this.$route.query && this.$route.query.package;
        for (let i = 0; i < this.packageInfo.length; i += 1) {
          if (this.packageInfo[i].key === packageKey) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.packageData.push(this.packageInfo[i]);
          }
        }
      }
      return true;
    },
    removeEndpoints() {
      let endpoint = this.packageData[0].allowed_endpoints;
      if (endpoint.includes('auth')) {
        endpoint = endpoint.filter((val) => val !== 'auth');
      } if (endpoint.includes('graphql')) {
        endpoint = endpoint.filter((val) => val !== 'graphql');
      } if (endpoint.includes('match-subscribe') && endpoint.includes('match-unsubscribe')) {
        endpoint = endpoint.filter((val) => (val !== 'match-subscribe' && val !== 'match-unsubscribe'));
        endpoint.push('match-via-push');
      } if ((endpoint.includes('fantasy-match-points-subscribe')
          && endpoint.includes('fantasy-match-points-unsubscribe'))) {
        endpoint = endpoint.filter((val) => (val !== 'fantasy-match-points-unsubscribe'
          && val !== 'fantasy-match-points-subscribe'));
        endpoint.push('fantasy-match-points-via-push');
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.allowed_endpoints = endpoint;
      return endpoint;
    },
  },

  mounted() {
    if (this.currency === 'inr') {
      this.cartPreviewInput.billingRegion = 'India';
    } else if (this.currency === 'usd') {
      this.cartPreviewInput.billingRegion = 'International';
    } else {
      this.cartPreviewInput.billingRegion = 'Europe';
    }
    this.cartPreviewInput.selectedLicenseKey = 'rs_package';
    this.cartPreviewInput.prepayAmount = 50;
    this.cartPreviewInput.prepayAmountKey = this.$route.query.package;
    this.$store.dispatch('payments/fetchCartPreview');
    this.fetchPackagePricing();
  },

  methods: {
    nextStep() {
      this.$router.push({
        name: 'projectCreation',
        query: {
          package: this.$route.query.package,
          quantity: this.cartPreview.items[0].quantity || 1,
          couponKey: (this.$route.query.couponKey !== 'null'
           && this.$route.query.couponKey !== 'undefined') ? this.$route.query.couponKey : null,
        },
      });
    },
    goBack() {
      this.$store.commit('payments/updateWizard', { step: WizardStep.SelectPlan });
    },
    fetchPackagePricing() {
      return this.$store.dispatch('paygo/fetchPackagePricing');
    },
  },
};
</script>
<style lang="scss" scoped>
.review-plan {
  margin-bottom: size(80);
  h4 {
    font-size: size(12);
    line-height: size(17);
    font-weight: 500;
    color: var(--rs-black-color);
    margin-bottom: size(10)
  }
  .variations-types-wrap {
    margin-top: size(20);
    max-width: size(540);
    padding: size(20);
    color: var(--rs-black-color);
    h2 {
      font-weight: 700;
      font-size: size(18);
      line-height: size(25);
      padding-bottom: size(20);
      margin-bottom: size(20);
      border-bottom: size(1) solid #E4E4E4;
    }
    p {
      font-weight: 400;
      font-size: size(15);
      line-height: size(21);
      padding-bottom: size(10);
    }
    h3 {
      font-size: size(14);
      line-height: size(18);
      font-weight: bold;
    }
    ul {
      margin-left: size(20);
      li {
        list-style-type: disc;
        font-size: size(14);
        line-height: size(20);
        padding-top: size(8);
        text-transform: capitalize;
        a {
          &:hover {
            text-decoration: underline;
            color: var(--rs-black-color);
          }
        }
      }
    }
  }
  .btn-wrap {
    .btn {
      width: size(140);
      font-size: size(12);
      line-height: size(17);
      font-weight: 400;
      padding: size(12) 0;
      border-radius: size(5);
    }
  }
}
</style>

<style lang="scss">
  .review-plan {
    .markdown {
      display: inline-block;
      .p {
        font-size: size(12);
        line-height: size(17);
        padding-top: size(10);
        margin-bottom: size(15);
        color: #000000;
      }
      em {
        font-style: italic;
        font-weight: normal;
      }
      strong {
        font-weight: bolder;
        em {
          font-weight: bolder;
        }
      }
      ul {
        list-style-type: disc;
        padding-left: size(20);
        margin-bottom: size(15);
        li {
          font-size: size(14);
          line-height: size(20);
          padding-top: size(6);
          color: #000000;
        }
      }
    }
  }
</style>
